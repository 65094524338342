import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import AllTestimonials from "../components/sections/AllTestimonials"
import { edgeToTestimonial } from "../lib/utils"
import { graphql } from 'gatsby'

const TestimonialsPage = ({ data }) => {

  const testimonials = data.testimonials.edges.map(edge => edgeToTestimonial({ edge }))

  return <Layout>
    <SEO title="Testimonials" />
    <AllTestimonials testimonials={testimonials} />
  </Layout>
}

export const query = graphql`
  query TestimonialsPageQuery {
    testimonials: allPrismicTestimonial(sort: {fields: data___order}) {
      edges {
        node {
          data {
            name {
              text
            }
            location {
              text
            }
            text {
              html
            }
            date {
              text
            }
            avatarnumber
            source
          }
        }
      }
    }
  }
`

export default TestimonialsPage
