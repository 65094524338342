import React from 'react'
import styled from 'styled-components'
import Avatar from './Avatar'

const Wrapper = styled.div`
    padding: 18px;
    background: #F8F8F8;
    border-radius: 4px;
    margin: 20px auto 0;
    max-width: 340px;
    text-align: left;
`
const Text = styled.div`
    font-size: 14px;
    line-height: 20px;
    color: #250044;
    padding: 20px 0 0;
    font-weight: normal;
`
const Title = styled.div`
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #250044;
`

const Titles = styled.div`
    flex: 1;
`

const Time = styled.div`
    margin: 5px 0 0 0;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #BDBDBD;
`

const sourceDimentions = (source) => {
    switch (source) {
        case '5stars': case '4stars': return `width: 59px; height: 11px;`;
        case 'facebook': return `width: 20px; height: 20px;`;
        case 'instagram': return `width: 20px; height: 20px;`;
        default: return '';
    }
}
const Source = styled.img`
    ${({ source }) => sourceDimentions(source)}
`
const Header = styled.div`
    display: flex;
    align-items: center;

    & ${Avatar} {
        margin-right: 12px;
    }
`

function Testimonial({ html: __html, name, location, date, avatarNumber, source, className }) {

    return <Wrapper className={className}>
        <Header>
            <Avatar src={`/avatars/${avatarNumber}.png`} size={50} />
            <Titles>
                <Title>{name} - {location}</Title>
                <Time>{date}</Time>
            </Titles>
            <Source src={`/sources/${source}.png`} source={source} />
        </Header>
        <Text dangerouslySetInnerHTML={{ __html }} />
    </Wrapper>
}

const StyledTestimonial = styled(Testimonial)``

export default StyledTestimonial