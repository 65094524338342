import React from 'react'
import styled from 'styled-components'
import Testimonial from './Testimonial'

const List = styled.div`
    margin: 0 auto;
`
const DesktopList = styled.div`
    display: none;
`
const Column = styled.div`
    margin: 0 10px;
    flex: 1;
`
const Wrapper = styled.div`
    border-radius: 4px;
    margin: -20px auto 0;
    max-width: 340px;

    @media (min-width: 834px) {

        padding: 0 18px;
        max-width: 100%;

        ${List} {
            display: none;
        } 

        ${DesktopList} {
            display: flex;
        } 

        max-width: 100%;
    }
`

function TestimonialList({ testimonials, className }) {

    const columns = [[], [], []]

    testimonials.forEach((t, i) => columns[i % 3].push(t))

    return <Wrapper className={className}>
        <List>
            {testimonials.map((t, i) => <Testimonial key={i} {...t} />)}
        </List>
        <DesktopList>
            {columns.map((c, i) => <Column key={i}>{c.map((t, i) => <Testimonial key={i} {...t} />)}</Column>)}
        </DesktopList>
    </Wrapper>
}

export default styled(TestimonialList)``