import React from 'react'
import Container from '../ui/Container'
import ItalicHeading from '../ui/ItalicHeading'
import styled from 'styled-components'
import TestimonialList from '../ui/TestimonialList'

const Wrapper = styled.div`
    margin: 56px auto 0;
    padding-bottom: 400px;

    ${TestimonialList} {
        margin: 52px auto 0;
    }

    @media(min-width: 834px) {
        margin: 72px auto 0;

        ${TestimonialList} {
            margin: 71px auto 0;
        }

    }
`

export default function AllTestimonials({ testimonials }) {

    return <Wrapper>
        <Container>
            <ItalicHeading>What our members</ItalicHeading>
            <ItalicHeading>have to say.</ItalicHeading>
            <TestimonialList testimonials={testimonials} />
        </Container>
    </Wrapper>
}